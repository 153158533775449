import {
  Container,
  Input,
  InputContainer,
  InputLabel,
  InputError,
  ButtonSecond,
  Center,
} from './style';
import { LocationSelector } from "../../../components/location-selector/location-selector";

import {
    Formik,
    Field,
    Form,
    FormikHelpers,
    ErrorMessage,
} from "formik";
import { useAuth } from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { useServiceUser } from "../../../hooks/serviceUser";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from 'react';
import { IUser } from '../../../models/User';

export default function MySpaceProfile() {

  const { t } = useTranslation();
  const { updateUser, getUser } = useServiceUser();
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
      const fetchUser = async () => {
          try {
              const userData = await getUser();
              setUser(userData);
          } catch (error) {
              console.error("Error fetching user:", error);
          }
      };

      fetchUser();
  }, []);

  if (!user) {
    return null;
  }

  const initialValues = {
    fullname: user?.fullname || "",
    email: user?.email || "",
    phone: user?.phone || "",
    document: user?.document || "",
    state_registration: user?.state_registration || "",
    password: "",
    new_password_confirm: "",
    zip_code: user?.zip_code || "",
    street: user?.street || "",
    house_number: user?.house_number || "",
    complement: user?.complement || "",
    neighborhood: user?.neighborhood || "",
    federal_unity: user?.federal_unity || "",
    city: user?.city || "",
    full_address: user?.address || "",
    lat: user?.lat || "",
    lng: user?.lng || "",
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    const data = {} as Parameters<typeof updateUser>[0];

    if (values.fullname.length > 0) {
      data.fullname = values.fullname;
    }

    if (values.phone.length > 0) {
      data.phone = values.phone;
    }

    if (values.state_registration.length > 0) {
      data.state_registration = values.state_registration;
    }

    if (values.password.length > 0) {
      data.password = values.password;
    }

    if (values.zip_code.length > 0) {
      data.zip_code = values.zip_code;
    }
    if (values.street.length > 0) {
      data.street = values.street;
    }
    if (values.house_number.length > 0) {
      data.house_number = values.house_number;
    }
    if (values.complement.length > 0) {
      data.complement = values.complement;
    }
    if (values.neighborhood.length > 0) {
      data.neighborhood = values.neighborhood;
    }
    if (values.federal_unity.length > 0) {
      data.federal_unity = values.federal_unity;
    }
    if (values.city.length > 0) {
      data.city = values.city;
    }
    data.address = values.full_address;
    data.lat = values.lat;
    data.lng = values.lng;

    try {
      await updateUser(values)
      .then((response) => {
        setUser(response);
      });
      toast.success(t("successfully"), {
          position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error((error as Error).message, {
        position: 'top-right',
      });
    }
  };

  const formValidation = (values: typeof initialValues) => {
    if (values.password.length > 0) {
      if (values.password !== values.new_password_confirm) {
        return {
          new_password_confirm: t("password-not-equal"),
        };
      }

    }

    if (values.fullname.length === 0) {
      return {
        fullname: t("field-required"),
      };
    }

    return {};
  };

  return (
      <Container>
          <ToastContainer />
          <Formik
              validate={formValidation}
              initialValues={initialValues}
              onSubmit={handleSubmit}
          >
              <Form>
                  <InputContainer>
                      <div>
                          <InputLabel>{t("corporate-reason")}</InputLabel>
                          <Field
                              as={Input}
                              type="text"
                              name="fullname"
                          />
                          <InputError>
                              <ErrorMessage name="fullname" />
                          </InputError>

                          <InputLabel>{t("phone")}:</InputLabel>
                          <Field as={Input} type="text" name="phone" />
                          <InputError>
                              <ErrorMessage name="phone" />
                          </InputError>

                          <InputLabel>Cnpj/Cpf:</InputLabel>
                          <Field
                              as={Input}
                              type="text"
                              name="document"
                              disabled
                          />
                          <InputError>
                              <ErrorMessage name="document" />
                          </InputError>

                          <InputLabel hidden={user?.document_type === "cpf"}>
                              {t("state-registration")}:
                          </InputLabel>
                          <Field
                              as={Input}
                              type="text"
                              name="state_registration"
                              style={{
                                  display: `${
                                      user?.document_type === "cpf"
                                          ? "none"
                                          : "block-inline"
                                  }`,
                              }}
                          />
                          <InputError>
                              <ErrorMessage name="state_registration" />
                          </InputError>

                          <LocationSelector />
                      </div>

                      <div>
                          <InputLabel>{t("email")}</InputLabel>
                          <Field
                              as={Input}
                              type="email"
                              name="email"
                              disabled
                          />
                          <InputError>
                              <ErrorMessage name="email" />
                          </InputError>

                          {/* <InputLabel>{t("old-password")}:</InputLabel>
                          <Field
                              as={Input}
                              type="password"
                              name="old_password"
                          />
                          <InputError>
                              <ErrorMessage name="old_password" />
                          </InputError> */}

                          <InputLabel>{t("new-password")}:</InputLabel>
                          <Field
                              as={Input}
                              type="password"
                              name="password"
                          />
                          <InputError>
                              <ErrorMessage name="new_password" />
                          </InputError>

                          <InputLabel>{t("confirm-new-password")}:</InputLabel>
                          <Field
                              as={Input}
                              type="password"
                              name="new_password_confirm"
                          />
                          <InputError>
                              <ErrorMessage name="new_password_confirm" />
                          </InputError>
                      </div>
                  </InputContainer>

                  <Center>
                      <ButtonSecond type="submit">{t("update")}</ButtonSecond>
                  </Center>
              </Form>
          </Formik>
      </Container>
  );
}
