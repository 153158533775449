import { useState } from "react";
import {
    ButtonEnter,
    Container,
    Content,
    LinhaVertical,
    Space,
    TitleButtonEnter,
    TitleInput,
    Title,
    DivImg,
    Img,
} from "./style";
import { useTranslation } from "react-i18next";
import { FaRegAddressCard } from "react-icons/fa6";
import { BeatLoader } from "react-spinners";
import { MdOutlineEmail } from "react-icons/md";
import { CiPhone } from "react-icons/ci";
import { MdCorporateFare } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";
import { LocationSelector } from "../../../components/location-selector/location-selector";
import Footer from "../../../components/footer/footer";
import Navbar from "../../../components/navbar/navbar";
import InputWithIcon from "../../../components/input/input";
import logo from "../../../assets/images/new-logo.png";

import {
    Formik,
    Field,
    Form,
    FieldInputProps,
    ErrorMessage,
    FormikHelpers,
} from "formik";

import ButtonSelect from "../../../components/button-select/ButtonSelect";
import { ErrorText, MarginBottom } from "../../login/login-seller/style";
import { useAuth } from "../../../context/AuthContext";
import registerValidationSellerPF from "../../../utils/validation/registerValidationSellerPF";
import registerValidationSellerPJ from "../../../utils/validation/registerValidationSellerPJ";
import MaskedInput from "../../../components/input/inputMask";
import { baseSeller } from "../../../utils/server/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import MapLocalize from "../../../components/googleMaps/MapLocalize";
import { useNavigate } from "react-router-dom";
import PasswordInputWithToggle from "../../../components/password-input-with-toggle/password-input-with-toggle";
import { LocationRegister } from "@/components/location-register/location-register";

export default function RegisterEngineer() {
    const [selectedOption, setSelectedOption] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const { registerSeller, registerError } = useAuth();
    const { t } = useTranslation();
    const handleSelectChange = (option: number) => {
        setSelectedOption(option);
    };
    const [addressComponent, setAddressComponent] = useState<any>();
    const [coverageArea, setCovoreageArea] = useState<any[]>([]);
    const navigate = useNavigate();

    const getAddressComponent = (addressData: any) => {
        setAddressComponent(addressData);
    };

    const initialValues = {
        type: selectedOption,
        business_name: "",
        corporate_name: "",
        document: "",
        cnpj: "",
        state_registration: "",
        phone: "",
        email: "",
        password: "",
        confirm_password: "",
        marital_status: "",
        identity: "",
        number_enrollment: "",
        natural: "",
        zip_code: "",
        street: "",
        house_number: "",
        complement: "",
        neighborhood: "",
        federal_unity: "",
        city: "",
        full_address: "",
        lat: "",
        lng: "",
    };

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        if (coverageArea.length == 0) {
            toast.error(t('add-coverage-area'), {
                position: 'bottom-center',
            });
            return;
        }

        let data: any = {};
        if (values.type === 1) {
            data = {
                document_type: "cpf",
                user_type: "engineer",
                fullname: values.business_name,
                cpf: values.document.replace(/[^0-9]/g, ""),
                phone: values.phone.replace(/[^0-9]/g, ""),
                email: values.email,
                password: values.password,
                marital_status: values.marital_status,
                identity: values.identity,
                natural: values.natural,
                number_enrollment: values.number_enrollment,
                zip_code: values.zip_code,
                street: values.street,
                house_number: values.house_number,
                complement: values.complement,
                neighborhood: values.neighborhood,
                federal_unity: values.federal_unity,
                city: values.city,
                address: values.full_address,
                lat: values.lat,
                lng: values.lng,
            };
        }

        if (values.type === 2) {
            data = {
                document_type: "cnpj",
                user_type: "engineer",
                fullname: values.business_name,
                company_name: values.corporate_name,
                cnpj: values.cnpj.replace(/[^0-9]/g, ""),
                state_registration: values.state_registration,
                phone: values.phone.replace(/[^0-9]/g, ""),
                email: values.email,
                password: values.password,
                marital_status: values.marital_status,
                identity: values.identity,
                natural: values.natural,
                number_enrollment: values.number_enrollment,
                zip_code: values.zip_code,
                street: values.street,
                house_number: values.house_number,
                complement: values.complement,
                neighborhood: values.neighborhood,
                federal_unity: values.federal_unity,
                city: values.city,
                address: values.full_address,
                lat: values.lat,
                lng: values.lng,
            };
        }

        data.engineer_coverage_locations = coverageArea;

        setIsLoading(true);
        await axios
            .post(`${baseSeller}/create`, data)
            .then(() => {
                toast.success(t("successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(function () {
                    navigate("/loginengineer");
                }, 3000);
            })
            .catch((error: any) => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    const errors = error.response.data.errors;

                    Object.keys(errors).forEach((field) => {
                        errors[field].forEach((errorMessage: string) => {
                            toast.error(t(errorMessage), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        });
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Container>
            <Navbar />
            <ToastContainer />
            <Container>
                <Content>
                    <Space>
                        <DivImg>
                            <Img src={logo} alt="Logo da ecolife" />
                        </DivImg>
                    </Space>
                    <LinhaVertical />
                    <Space>
                        <Title>{t("comment-engineer")}</Title>
                        <ButtonSelect
                            onSelectChange={handleSelectChange}
                            options="never"
                        />

                        <Formik
                            initialValues={initialValues}
                            validationSchema={
                                selectedOption === 1
                                    ? registerValidationSellerPF
                                    : registerValidationSellerPJ
                            }
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            <Form>
                                <TitleInput> {t("name")}</TitleInput>
                                <Field
                                    type="text"
                                    name="business_name"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <InputWithIcon
                                            icon={<FaWpforms />}
                                            iconSize="23px"
                                            field={field}
                                            placeholder={t("type-name")}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) =>
                                        error.field === "business_name"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field === "business_name"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="business_name" />
                                    </ErrorText>
                                )}

                                <MarginBottom />
                                {selectedOption === 1 && (
                                    <>
                                        <TitleInput>CPF</TitleInput>
                                        <Field
                                            type="text"
                                            name="document"
                                            render={({
                                                field,
                                            }: {
                                                field?: FieldInputProps<any>;
                                            }) => (
                                                <MaskedInput
                                                    field={field}
                                                    icon={<FaRegAddressCard />}
                                                    mask="999.999.999-99"
                                                    iconSize="23px"
                                                    placeholder={t(
                                                        "type-document"
                                                    )}
                                                />
                                            )}
                                        />

                                        {registerError.some(
                                            (error: any) =>
                                                error.field === "document"
                                        ) ? (
                                            registerError
                                                .filter(
                                                    (error: any) =>
                                                        error.field ===
                                                        "document"
                                                )
                                                .map(
                                                    (
                                                        error: any,
                                                        index: any
                                                    ) => (
                                                        <ErrorText key={index}>
                                                            <p>{error.msgs}</p>
                                                        </ErrorText>
                                                    )
                                                )
                                        ) : (
                                            <ErrorText>
                                                <ErrorMessage name="document" />
                                            </ErrorText>
                                        )}

                                        <MarginBottom />
                                    </>
                                )}

                                <MarginBottom />

                                <TitleInput> {t("identity")}</TitleInput>
                                <Field
                                    type="text"
                                    name="identity"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <InputWithIcon
                                            icon={<FaWpforms />}
                                            iconSize="23px"
                                            field={field}
                                            placeholder={t("type-identity")}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) => error.field === "identity"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field === "identity"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="identity" />
                                    </ErrorText>
                                )}

                                <MarginBottom />

                                <TitleInput> {t("marital-status")}</TitleInput>
                                <Field
                                    type="text"
                                    name="marital_status"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <InputWithIcon
                                            icon={<FaWpforms />}
                                            iconSize="23px"
                                            field={field}
                                            placeholder={t("type-marital")}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) =>
                                        error.field === "marital_status"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field === "marital_status"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="marital_status" />
                                    </ErrorText>
                                )}

                                <MarginBottom />

                                <TitleInput> {t("naturalness")}</TitleInput>
                                <Field
                                    type="text"
                                    name="natural"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <InputWithIcon
                                            icon={<FaWpforms />}
                                            iconSize="23px"
                                            field={field}
                                            placeholder={t("type-natural")}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) => error.field === "natural"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field === "natural"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="natural" />
                                    </ErrorText>
                                )}

                                <MarginBottom />

                                <TitleInput>
                                    {" "}
                                    {t("number-enrollment")}
                                </TitleInput>
                                <Field
                                    type="text"
                                    name="number_enrollment"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <InputWithIcon
                                            icon={<FaWpforms />}
                                            iconSize="23px"
                                            field={field}
                                            placeholder={t(
                                                "type-number-enrollment"
                                            )}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) =>
                                        error.field === "number_enrollment"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field ===
                                                "number_enrollment"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="number_enrollment" />
                                    </ErrorText>
                                )}

                                <MarginBottom />

                                <TitleInput> {t("phone")}</TitleInput>
                                <Field
                                    type="text"
                                    name="phone"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <MaskedInput
                                            field={field}
                                            icon={<CiPhone />}
                                            mask="(99) 99999-9999"
                                            iconSize="23px"
                                            placeholder={t("type-phone")}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) => error.field === "phone"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field === "phone"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="phone" />
                                    </ErrorText>
                                )}

                                <MarginBottom />

                                {selectedOption === 2 && (
                                    <>
                                        <TitleInput>CNPJ</TitleInput>
                                        <Field
                                            type="text"
                                            name="cnpj"
                                            render={({
                                                field,
                                            }: {
                                                field?: FieldInputProps<any>;
                                            }) => (
                                                <MaskedInput
                                                    field={field}
                                                    icon={<FaRegAddressCard />}
                                                    mask="99.999.999/9999-99"
                                                    iconSize="23px"
                                                    placeholder={t("type-cnpj")}
                                                />
                                            )}
                                        />

                                        {registerError.some(
                                            (error: any) =>
                                                error.field === "cnpj"
                                        ) ? (
                                            registerError
                                                .filter(
                                                    (error: any) =>
                                                        error.field === "cnpj"
                                                )
                                                .map(
                                                    (
                                                        error: any,
                                                        index: any
                                                    ) => (
                                                        <ErrorText key={index}>
                                                            <p>{error.msgs}</p>
                                                        </ErrorText>
                                                    )
                                                )
                                        ) : (
                                            <ErrorText>
                                                <ErrorMessage name="cnpj" />
                                            </ErrorText>
                                        )}
                                    </>
                                )}

                                <MarginBottom />

                                {selectedOption === 2 && (
                                    <>
                                        <TitleInput>
                                            {" "}
                                            {t("corporate-reason")}
                                        </TitleInput>
                                        <Field
                                            type="text"
                                            name="corporate_name"
                                            render={({
                                                field,
                                            }: {
                                                field?: FieldInputProps<any>;
                                            }) => (
                                                <InputWithIcon
                                                    icon={<MdCorporateFare />}
                                                    iconSize="23px"
                                                    field={field}
                                                    placeholder={t(
                                                        "type-company-name"
                                                    )}
                                                />
                                            )}
                                        />

                                        {registerError.some(
                                            (error: any) =>
                                                error.field === "corporate_name"
                                        ) ? (
                                            registerError
                                                .filter(
                                                    (error: any) =>
                                                        error.field ===
                                                        "corporate_name"
                                                )
                                                .map(
                                                    (
                                                        error: any,
                                                        index: any
                                                    ) => (
                                                        <ErrorText key={index}>
                                                            <p>{error.msgs}</p>
                                                        </ErrorText>
                                                    )
                                                )
                                        ) : (
                                            <ErrorText>
                                                <ErrorMessage name="corporate_name" />
                                            </ErrorText>
                                        )}
                                    </>
                                )}

                                <MarginBottom />

                                {selectedOption === 2 && (
                                    <>
                                        <TitleInput>
                                            {" "}
                                            {t("state-registration")}
                                        </TitleInput>
                                        <Field
                                            type="number"
                                            name="state_registration"
                                            render={({
                                                field,
                                            }: {
                                                field?: FieldInputProps<any>;
                                            }) => (
                                                <InputWithIcon
                                                    icon={<MdCorporateFare />}
                                                    iconSize="23px"
                                                    field={field}
                                                    placeholder={t(
                                                        "type-state-registration"
                                                    )}
                                                    onChange={(e) => {
                                                        if (field) {
                                                            const numericValue =
                                                                e.target.value.replace(
                                                                    /\D/g,
                                                                    ""
                                                                );
                                                            field.onChange({
                                                                target: {
                                                                    value: numericValue,
                                                                    name: field.name,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        if (field) {
                                                            const numericValue =
                                                                e.target.value.replace(
                                                                    /\D/g,
                                                                    ""
                                                                );
                                                            field.onChange({
                                                                target: {
                                                                    value: numericValue,
                                                                    name: field.name,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                />
                                            )}
                                        />

                                        {registerError.some(
                                            (error: any) =>
                                                error.field ===
                                                "state_registration"
                                        ) ? (
                                            registerError
                                                .filter(
                                                    (error: any) =>
                                                        error.field ===
                                                        "state_registration"
                                                )
                                                .map(
                                                    (
                                                        error: any,
                                                        index: any
                                                    ) => (
                                                        <ErrorText key={index}>
                                                            <p>{error.msgs}</p>
                                                        </ErrorText>
                                                    )
                                                )
                                        ) : (
                                            <ErrorText>
                                                <ErrorMessage name="state_registration" />
                                            </ErrorText>
                                        )}
                                    </>
                                )}

                                <MarginBottom />

                                <TitleInput>{t("email")}</TitleInput>
                                <Field
                                    type="text"
                                    name="email"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <InputWithIcon
                                            icon={<MdOutlineEmail />}
                                            iconSize="23px"
                                            field={field}
                                            placeholder={t("type-email")}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) => error.field === "email"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field === "email"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="email" />
                                    </ErrorText>
                                )}

                                <MarginBottom />

                                <LocationSelector />

                                <MarginBottom />

                                <TitleInput>{t("coverage-area")}</TitleInput>
                                <LocationRegister
                                  onLocationSelected={(locations) => setCovoreageArea(locations.map(l => ({
                                    uf: l.state_uf,
                                    city: l.name,
                                    state_uf: l.state_name,
                                  })))} />

                                <MarginBottom />
                                <TitleInput>{t("password")}</TitleInput>
                                <Field
                                    type="password"
                                    name="password"
                                    render={({
                                        field,
                                    }: {
                                        field: FieldInputProps<any>;
                                    }) => (
                                        <PasswordInputWithToggle
                                            field={field}
                                            placeholder={t("type-password")}
                                        />
                                    )}
                                />

                                {registerError.some(
                                    (error: any) => error.field === "password"
                                ) ? (
                                    registerError
                                        .filter(
                                            (error: any) =>
                                                error.field === "password"
                                        )
                                        .map((error: any, index: any) => (
                                            <ErrorText key={index}>
                                                <p>{error.msgs}</p>
                                            </ErrorText>
                                        ))
                                ) : (
                                    <ErrorText>
                                        <ErrorMessage name="password" />
                                    </ErrorText>
                                )}

                                <MarginBottom />

                                <TitleInput>{t("confirm-password")}</TitleInput>
                                <Field
                                    type="text"
                                    name="confirm_password"
                                    render={({
                                        field,
                                    }: {
                                        field?: FieldInputProps<any>;
                                    }) => (
                                        <PasswordInputWithToggle
                                            field={field}
                                            placeholder={t("type-password")}
                                        />
                                    )}
                                />

                                <ErrorText>
                                    <ErrorMessage name="confirm_password" />
                                </ErrorText>

                                <MarginBottom />

                                <ButtonEnter type="submit">
                                    {isLoading ? (
                                        <BeatLoader className="spinner" />
                                    ) : (
                                        <TitleButtonEnter>
                                            {t("register")}
                                        </TitleButtonEnter>
                                    )}
                                </ButtonEnter>
                            </Form>
                        </Formik>
                    </Space>
                </Content>
            </Container>
            <Footer />
        </Container>
    );
}
