import React, { useEffect, useState, useCallback } from 'react';
import { useServiceHome } from '../../hooks/serviceHome';
import { useTranslation } from 'react-i18next';
import InputWithIcon from '../input/input';
import { FaWpforms } from 'react-icons/fa';
import { ErrorMessage, Field, FieldInputProps, useFormikContext } from 'formik';
import { ErrorText, MarginBottom } from '../../screens/login/login-seller/style';
import styled from "styled-components";

interface PropertyLocationSelectorProps {
    onLocationSelected?: (uf: string, city: string) => void;
}

const TitleInput = styled.p`
    font-family: "poppins";
    color: #000000;
`;

export const PropertyLocationSelector: React.FC<PropertyLocationSelectorProps> = ({ onLocationSelected }) => {
    const [ufs, setUfs] = useState<Array<{id: number, name: string, uf: string}>>([]);
    const [cities, setCities] = useState<Array<{id: number, name: string}>>([]);
    const [selectedUf, setSelectedUf] = useState('');
    const { getUfs, getCities } = useServiceHome();
    const { t } = useTranslation();
    const formik = useFormikContext();

    useEffect(() => {
        const loadUfs = async () => {
            try {
                const response = await getUfs();
                setUfs(response);
                const values = formik.values as any;
                console.log(values);
                if (values.federal_unity) {
                    setSelectedUf(values.federal_unity);
                }
            } catch (error) {
                console.error('Error loading UFs:', error);
            }
        };
        loadUfs();
    }, []);

    useEffect(() => {
        const loadCities = async () => {
            if (selectedUf) {
                try {
                    const response = await getCities(selectedUf);
                    setCities(response);
                } catch (error) {
                    console.error('Error loading cities:', error);
                }
            } else {
                setCities([]);
            }
        };
        loadCities();
    }, [selectedUf]);

    const handleUfChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const uf = e.target.value;
        setSelectedUf(uf);
        if (onLocationSelected) {
            onLocationSelected(uf, '');
        }
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const city = e.target.value;
        if (onLocationSelected) {
            onLocationSelected(selectedUf, city);
        }
    };

    return (
      <>
        <TitleInput>{t("address")}</TitleInput>
        <Field
            type="text"
            name="address"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                    placeholder={t("type-address")}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="address" />
        </ErrorText>

        <MarginBottom />
          <TitleInput>{t("federal_unity")}</TitleInput>
          <Field name="federal_unity">
              {({ field }: { field: FieldInputProps<any> }) => (
                  <select 
                      {...field}
                      onChange={(e) => {
                          field.onChange(e);
                          handleUfChange(e);
                      }}
                      style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc'
                      }}
                  >
                      <option value="">{t("select-state")}</option>
                      {ufs.map((uf) => (
                          <option key={uf.id} value={uf.uf}>
                              {uf.name}
                          </option>
                      ))}
                  </select>
              )}
          </Field>
          <ErrorText>
              <ErrorMessage name="federal_unity" />
          </ErrorText>

          <MarginBottom />

          <TitleInput>{t("city")}</TitleInput>
          <Field name="city">
              {({ field }: { field: FieldInputProps<any> }) => (
                  <select 
                      {...field}
                      onChange={(e) => {
                          field.onChange(e);
                          handleCityChange(e);
                      }}
                      style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc'
                      }}
                      disabled={!selectedUf}
                  >
                      <option value="">{t("select-city")}</option>
                      {cities.map((city) => (
                          <option key={city.id} value={city.name}>
                              {city.name}
                          </option>
                      ))}
                  </select>
              )}
          </Field>
          <ErrorText>
              <ErrorMessage name="city" />
          </ErrorText>

        <TitleInput>Latitude</TitleInput>
        <Field
            type="text"
            name="lat"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="lat" />
        </ErrorText>

        <TitleInput>Longitude</TitleInput>
        <Field
            type="text"
            name="lng"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="lng" />
        </ErrorText>
      </>
  );
};
