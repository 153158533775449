import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";

import {
    Button,
    Center,
    Container,
    FileListContainer,
    Input,
    InputContainer,
    InputError,
    InputLabel,
    Main,
    Select,
    Title,
    Textarea,
    Buttonback,
} from "./style";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useServiceProperty } from "../../hooks/serviceProperty";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadSquare from "../../components/upload/fileUploadSquare";
import { storageServerPath } from "../../utils/server/api";
import ModalConfirmation from "../../components/alerts/modalConfirmation";
import { useAuth } from "../../context/AuthContext";
import { useServiceHome } from "../../hooks/serviceHome";
import { PropertyLocationSelector } from "../../components/location-selector/property-location-selector";

export default function UpdateProperty() {
    const { id } = useParams();
    const [fileDelete, setFileDelete] = useState("");
    const { user } = useAuth();
    const [columnDelete, setColumnDelete] = useState("");
    const { updateProperty, getProperty, removeFile } = useServiceProperty(id);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [files, setFiles] = useState({
        file_deed_number: "",
        file_cib: "",
        file_proof_of_residence: "",
        file_images: "",
    });
    const [addressComponent, setAddressComponent] = useState<any>();
    const onFileSelected = (name: string) => (file: string) => {
        setFiles({ ...files, [name]: file });
    };
    const [property, setProperty] = useState<any>();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [detailsFile, setDetailsFile] = useState<any>({});
    const [buttonSave, setButtonSave] = useState(t("save"));
    const [clearFilesUpload, setClearFilesUpload] = useState(false);
    const [catalog, setCatalog] = useState("sell");

    const { getVegetations } = useServiceHome();
    const [vegetations, setVegetations] = useState<any[]>([]);

    useEffect(() => {
        async function fetchProperty() {
            const data = await getProperty();
            setProperty(data);
        }

        async function getVegetationsAll() {
            await getVegetations()
                .then((response: any) => {
                    setVegetations(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        getVegetationsAll();

        function analisePage() {
            if (
                user?.user_type === "engineer" &&
                property?.engineer_connect !== null &&
                property?.engineer_connect !== user?.id
            ) {
                navigate("/news");
            }
        }

        if (!property) {
            fetchProperty();
        }

        if (property) {
            analisePage();
        }

        setCatalog(property?.status === "catalog" ? "catalog" : "sell");
    }, [property]);

    useEffect(() => {
        if (fileDelete && columnDelete) {
            removeFileApi();
        }
    }, [fileDelete, columnDelete]);

    const updateData = async () => {
        const data = await getProperty();
        setProperty(data);
    };

    const initialValues = {
        catalogs: property?.status === "catalog" ? "catalog" : "sell",
        name: property?.name || "",
        deed_number: property?.deed_number || "",
        acre: property?.acre || "",
        cib_nirf_number: property?.cib_nirf_number || "",
        description: property?.description || "",
        vegetation: property?.vegetation || "dense",
        address: property?.address || "",
        lat: property?.lat || "",
        lng: property?.lng || "",
        city: property?.city || "",
        federal_unity: property?.federal_unity || "",
    };

    const formValidation = (values: typeof initialValues) => {};

    const alterOpenModalDelete = async (value: boolean, status: boolean) => {
        setOpenModalDelete(value);

        if (status === true) {
            setFileDelete(detailsFile.file);
            setColumnDelete(detailsFile.column);
        }
    };

    const removeFileApi = async () => {
        await removeFile({
            file: fileDelete,
            column: columnDelete,
        })
            .then((response: any) => {
                toast.success(t("successfully"), {
                    position: "top-right",
                });

                updateData();
            })
            .catch((error: Error) => {
                toast.error(error.message, {
                    position: "top-right",
                });
            });
    };

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        setButtonSave(`${t("waiting")}...`);
        values.catalogs = catalog;

        await updateProperty({
            ...values,
            ...files,
        } as any)
            .then((response: any) => {
                toast.success(t("successfully"), {
                    position: "top-right",
                });

                updateData();

                setClearFilesUpload(true);
            })
            .catch((error: any) => {
                if (error.response && error.response.data) {
                    const errors = error.response.data.errors;

                    const errors2 = error.response.data.message;

                    if (errors2 && !errors) {
                        toast.error(t(errors2), {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }

                    Object.keys(errors).forEach((field) => {
                        errors[field].forEach((errorMessage: string) => {
                            toast.error(t(errorMessage), {
                                position: toast.POSITION.TOP_CENTER,
                            });
                        });
                    });
                }
            })
            .finally(() => {
                setButtonSave(`${t("save")}`);
            });
    };

    return (
        <Container>
            <Navbar />
            <ToastContainer />

            <Main>
                <p>
                    <Buttonback
                        type={"button"}
                        onClick={() => {
                            navigate(`/detailsPropertySeller/${id}`);
                        }}
                    >
                        {"< "}
                        {t("back")}
                    </Buttonback>
                </p>
                <Title>{t("update-property")}</Title>
                {property && (
                    <>
                        <Formik
                            validate={formValidation}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <InputContainer>
                                    <div
                                        style={{
                                            width: "100%",
                                            borderBottom: "1px solid #eee",
                                            paddingBottom: "30px",
                                            marginBottom: "30px",
                                        }}
                                    >
                                        <InputLabel>
                                            {t("how_register")}
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            type="text"
                                            name="catalog"
                                            value={catalog}
                                            required
                                            onChange={(event: any) => {
                                                const value =
                                                    event.target.value;
                                                setCatalog(value);
                                            }}
                                        >
                                            <option key={"sell"} value={"sell"}>
                                                {t("i-seller")}
                                            </option>
                                            <option
                                                key={"catalog"}
                                                value={"catalog"}
                                            >
                                                {t("i-catalog")}
                                            </option>
                                        </Field>
                                    </div>
                                </InputContainer>
                                <InputContainer>
                                    <div>
                                        <InputLabel>{t("name")}</InputLabel>
                                        <Field
                                            as={Input}
                                            type="text"
                                            name="name"
                                            minLength={6}
                                            required
                                        />
                                        <InputError>
                                            <ErrorMessage name="name" />
                                        </InputError>

                                        <InputLabel>{t("hectares")}</InputLabel>
                                        <Field
                                            as={Input}
                                            type="text"
                                            name="acre"
                                            required
                                        />
                                        <InputError>
                                            <ErrorMessage name="acre" />
                                        </InputError>

                                        <InputLabel>
                                            {t("number-doc")}
                                        </InputLabel>
                                        <Field
                                            as={Input}
                                            type="text"
                                            name="cib_nirf_number"
                                            required={catalog === "sell"}
                                            disabled={catalog === "catalog"}
                                        />
                                        <InputError>
                                            <ErrorMessage name="cib_nirf_number" />
                                        </InputError>

                                        <InputLabel>
                                            {t("vegetation")}
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            type="text"
                                            name="vegetation"
                                            required
                                        >
                                            {vegetations.map((v) => (
                                                <option
                                                    key={v.slug}
                                                    value={v.slug}
                                                >
                                                    {t(`vegetation-${v.slug}`)}
                                                </option>
                                            ))}
                                        </Field>
                                        <InputError>
                                            <ErrorMessage name="vegetation" />
                                        </InputError>
                                        <InputLabel
                                            style={{ margin: "20px 0" }}
                                        >
                                            <i>
                                                {t("carbon_credits_available")}
                                                :&nbsp;
                                                {
                                                    property?.carbon_credits_available
                                                }
                                            </i>
                                        </InputLabel>
                                        <InputLabel>
                                            {t("deed-number")}
                                        </InputLabel>
                                        <Field
                                            as={Input}
                                            type="text"
                                            name="deed_number"
                                            required={catalog === "sell"}
                                            disabled={catalog === "catalog"}
                                        />
                                        <InputError>
                                            <ErrorMessage name="deed_number" />
                                        </InputError>

                                    </div>

                                    <div>
                                        <PropertyLocationSelector />
                                    </div>
                                </InputContainer>
                                <InputContainer>
                                    <div style={{ height: "auto" }}>
                                        <InputLabel>
                                            {t("description")}
                                        </InputLabel>
                                        <Field
                                            as={Textarea}
                                            name="description"
                                        />
                                        <InputError>
                                            <ErrorMessage name="description" />
                                        </InputError>
                                    </div>
                                </InputContainer>
                                {user?.user_type === "engineer" && (
                                    <>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                window.open(
                                                    "https://coletorcafir.receita.fazenda.gov.br/coletor/consulta/consultaCafir.jsf"
                                                );
                                            }}
                                            style={{
                                                marginLeft: "250px",
                                            }}
                                        >
                                            {t("valid-cib")}
                                        </Button>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                window.open(
                                                    "https://sncr.serpro.gov.br/ccir/consulta?windowId=516"
                                                );
                                            }}
                                            style={{
                                                marginLeft: "50px",
                                            }}
                                        >
                                            {t("valid-ccir")}
                                        </Button>
                                    </>
                                )}

                                {/* <InputContainer>
                                    <div style={{ height: "300px" }}>
                                        <InputLabel>{t("address")}</InputLabel>
                                        <MapLocalize
                                            getAddressComponent={
                                                getAddressComponent
                                            }
                                            setAddress={property?.address}
                                            setLat={
                                                parseFloat(property?.lat) || 0
                                            }
                                            setLng={parseFloat(
                                                property?.lng || 0
                                            )}
                                            view={true}
                                        />
                                    </div>
                                </InputContainer> */}

                                <InputContainer>
                                    <div>
                                        <InputLabel>{t("file")}</InputLabel>
                                        <FileListContainer>
                                            {catalog === "sell" && (
                                                <>
                                                    <FileUploadSquare
                                                        title="CCIR"
                                                        onFileSelected={onFileSelected(
                                                            "file_cib"
                                                        )}
                                                        clearFiles={
                                                            clearFilesUpload
                                                        }
                                                    />
                                                    <FileUploadSquare
                                                        title={t(
                                                            "proof-residence"
                                                        )}
                                                        onFileSelected={onFileSelected(
                                                            "file_proof_of_residence"
                                                        )}
                                                        clearFiles={
                                                            clearFilesUpload
                                                        }
                                                    />
                                                    <FileUploadSquare
                                                        title={t("deed-file")}
                                                        onFileSelected={onFileSelected(
                                                            "file_deed_number"
                                                        )}
                                                        clearFiles={
                                                            clearFilesUpload
                                                        }
                                                    />
                                                </>
                                            )}
                                            <FileUploadSquare
                                                title={t("image-property")}
                                                multiple={true}
                                                onFileSelected={onFileSelected(
                                                    "file_images"
                                                )}
                                                clearFiles={clearFilesUpload}
                                            />
                                        </FileListContainer>
                                    </div>
                                </InputContainer>

                                <Center>
                                    <Button type="submit">{buttonSave}</Button>
                                </Center>
                            </Form>
                        </Formik>
                        <h2 style={{ marginBottom: 15, fontWeight: "bold" }}>
                            {t("attachments")}
                        </h2>
                        <ul>
                            <li>
                                <a
                                    href={
                                        property.file_cib
                                            ? storageServerPath +
                                              property.file_cib
                                            : "#"
                                    }
                                    target={
                                        property.file_cib ? "blank" : "_self"
                                    }
                                >
                                    CCIR
                                </a>{" "}
                                {property.file_cib && (
                                    <button
                                        onClick={() => {
                                            setOpenModalDelete(true);
                                            setDetailsFile({
                                                file: property.file_cib,
                                                column: "file_cib",
                                            });
                                        }}
                                        style={{
                                            marginLeft: 5,
                                            color: "red",
                                        }}
                                    >
                                        {t("to-remove")}
                                    </button>
                                )}
                            </li>
                            <li>
                                <a
                                    href={
                                        property.file_proof_of_residence
                                            ? storageServerPath +
                                              property.file_proof_of_residence
                                            : "#"
                                    }
                                    target={
                                        property.file_proof_of_residence
                                            ? "blank"
                                            : "_self"
                                    }
                                >
                                    {t("proof-residence")}
                                </a>{" "}
                                {property.file_proof_of_residence && (
                                    <button
                                        onClick={() => {
                                            setOpenModalDelete(true);
                                            setDetailsFile({
                                                file: property.file_proof_of_residence,
                                                column: "file_proof_of_residence",
                                            });
                                        }}
                                        style={{
                                            marginLeft: 5,
                                            color: "red",
                                        }}
                                    >
                                        {t("to-remove")}
                                    </button>
                                )}
                            </li>
                            <li>
                                <a
                                    href={
                                        property.file_deed_number
                                            ? storageServerPath +
                                              property.file_deed_number
                                            : "#"
                                    }
                                    target={
                                        property.file_deed_number
                                            ? "blank"
                                            : "_self"
                                    }
                                >
                                    {t("deed-file")}
                                </a>{" "}
                                {property.file_deed_number && (
                                    <button
                                        onClick={() => {
                                            setOpenModalDelete(true);
                                            setDetailsFile({
                                                file: property.file_deed_number,
                                                column: "file_deed_number",
                                            });
                                        }}
                                        style={{
                                            marginLeft: 5,
                                            color: "red",
                                        }}
                                    >
                                        {t("to-remove")}
                                    </button>
                                )}
                            </li>
                        </ul>
                        <h2
                            style={{
                                marginTop: 20,
                                marginBottom: 15,
                                fontWeight: "bold",
                            }}
                        >
                            {t("images")}
                        </h2>
                        {property?.file_images
                            ?.split(";")
                            .map((image: any, index: number) => {
                                return (
                                    <ul key={index}>
                                        <li>
                                            <a
                                                href={
                                                    image
                                                        ? storageServerPath +
                                                          image
                                                        : "#"
                                                }
                                                target={
                                                    image ? "blank" : "_self"
                                                }
                                            >
                                                <img
                                                    style={{
                                                        width: "100px",
                                                    }}
                                                    src={
                                                        image
                                                            ? storageServerPath +
                                                              image
                                                            : "#"
                                                    }
                                                    alt=""
                                                />
                                            </a>{" "}
                                            {image && (
                                                <button
                                                    onClick={() => {
                                                        setOpenModalDelete(
                                                            true
                                                        );
                                                        setDetailsFile({
                                                            file: image,
                                                            column: "file_images",
                                                        });
                                                    }}
                                                    style={{
                                                        marginLeft: 5,
                                                        color: "red",
                                                    }}
                                                >
                                                    {t("to-remove")}
                                                </button>
                                            )}
                                        </li>
                                    </ul>
                                );
                            })}
                        <ModalConfirmation
                            show={openModalDelete}
                            alterOpenModalDelete={alterOpenModalDelete}
                            message={t("delete-confirmation-file")}
                        />
                    </>
                )}
            </Main>
            <Footer />
        </Container>
    );
}
