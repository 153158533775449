import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { apiBase } from "../utils/server/api";
import { UfData, CityData, ZipCodeData, GeocodeAddressData } from '../models/LocationData';

const getHome =
    (config: ReturnType<typeof useAuth>["headerConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/home-site`, config())
            .then((response: any) => {
                return response.data;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getPronunciamentos =
    (config: ReturnType<typeof useAuth>["headerConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/pronouncement`, config())
            .then((response: any) => {
                return response.data;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getVegetations =
    (config: ReturnType<typeof useAuth>["headerConfig"]) => async () => {
        return await axios
            .get(`${apiBase}/vegetation`, config())
            .then((response: any) => {
                return response.data;
            })
            .catch((error: Error) => {
                console.log(error);
                throw error;
            });
    };

const getUfs =
  (config: ReturnType<typeof useAuth>["headerConfig"]) =>
    async (): Promise<UfData[]> => {
      return await axios
          .get(`${apiBase}/uf`, config())
          .then((response: any) => {
              return response.data;
          })
          .catch((error: Error) => {
              console.log(error);
              throw error;
          });
  };

const getCitiesByUf =
  (config: ReturnType<typeof useAuth>["headerConfig"]) =>
    async (uf: string): Promise<CityData[]> => {
      return await axios
          .get(`${apiBase}/city/${uf}`, config())
          .then((response: any) => {
              return response.data;
          })
          .catch((error: Error) => {
              console.log(error);
              throw error;
          });
  };

const getAddressDataByZipCode =
  (config: ReturnType<typeof useAuth>["headerConfig"]) =>
    async (zipCode: string): Promise<ZipCodeData> => {
      return await axios
          .get(`${apiBase}/cep/${zipCode}`, config())
          .then((response: any) => {
              return response.data;
          })
          .catch((error: Error) => {
              console.log(error);
              throw error;
          });
  };

const getGeocodeFromAddress =
  (config: ReturnType<typeof useAuth>["headerConfig"]) =>
    async (address: string): Promise<GeocodeAddressData> => {
      return await axios
          .post(`${apiBase}/geocode`, { address }, config())
          .then((response: any) => {
              return response.data;
          })
          .catch((error: Error) => {
              console.log(error);
              throw error;
          });
  };

export function useServiceHome() {
    const { axiosConfig } = useAuth();

    return {
        getHome: getHome(axiosConfig),
        getPronunciamentos: getPronunciamentos(axiosConfig),
        getVegetations: getVegetations(axiosConfig),
        getUfs: getUfs(axiosConfig),
        getCities: getCitiesByUf(axiosConfig),
        getAddressDataByZipCode: getAddressDataByZipCode(axiosConfig),
        getGeocodeFromAddress: getGeocodeFromAddress(axiosConfig)
    };
}
