import React, { useEffect, useState, useCallback } from 'react';
import { useServiceHome } from '../../hooks/serviceHome';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import InputWithIcon from '../input/input';
import { FaWpforms } from 'react-icons/fa';
import { ErrorMessage, Field, FieldInputProps, useFormikContext } from 'formik';
import { ErrorText, MarginBottom } from '../../screens/login/login-seller/style';
import styled from "styled-components";

interface LocationSelectorProps {
    onLocationSelected?: (uf: string, city: string) => void;
}

const TitleInput = styled.p`
    font-family: "poppins";
    color: #000000;
`;

export const LocationSelector: React.FC<LocationSelectorProps> = ({ onLocationSelected }) => {
    const [ufs, setUfs] = useState<Array<{id: number, name: string, uf: string}>>([]);
    const [cities, setCities] = useState<Array<{id: number, name: string}>>([]);
    const [selectedUf, setSelectedUf] = useState('');
    const { getUfs, getCities, getAddressDataByZipCode, getGeocodeFromAddress } = useServiceHome();
    const { t } = useTranslation();
    const formik = useFormikContext();

    useEffect(() => {
        const loadUfs = async () => {
            try {
                const response = await getUfs();
                setUfs(response);
                const values = formik.values as any;
                if (values.federal_unity) {
                    setSelectedUf(values.federal_unity);
                }
            } catch (error) {
                console.error('Error loading UFs:', error);
            }
        };
        loadUfs();
    }, []);

    useEffect(() => {
        const loadCities = async () => {
            if (selectedUf) {
                try {
                    const response = await getCities(selectedUf);
                    setCities(response);
                } catch (error) {
                    console.error('Error loading cities:', error);
                }
            } else {
                setCities([]);
            }
        };
        loadCities();
    }, [selectedUf]);

    const debouncedGeocodeAddress = useCallback(
        debounce(async (address: string) => {
            // Only make the request if it's the latest address
            const currentAddress = (formik.values as any).full_address;
            // if (address === currentAddress) {
                const response = await getGeocodeFromAddress(address);
                if (response) {
                    formik.setFieldValue('lat', response.lat);
                    formik.setFieldValue('lng', response.lng);
                }
            // }
        }, 1000),
        [getGeocodeFromAddress, formik]
    );

    useEffect(() => {
        const values = formik.values as any;
        const { zip_code, street, house_number, complement, neighborhood, city, federal_unity, full_address } = values;
        
        const fullAddress = `${street || ''}, ${house_number || ''}, ${complement || ''}, ${neighborhood || ''}, ${city || ''} - ${federal_unity || ''} (${zip_code || ''})`.replace(/,\s+,/g, ',').replace(/^\s*,\s*/, '');

        const anyAddressFieldIsEmpty =
          !zip_code || !city || !federal_unity || !street || !neighborhood;

        if (anyAddressFieldIsEmpty || fullAddress === full_address) {
            return;
        }

        formik.setFieldValue('full_address', fullAddress, false);
        debouncedGeocodeAddress(fullAddress);
    }, [
        (formik.values as any).zip_code,
        (formik.values as any).street,
        (formik.values as any).house_number,
        (formik.values as any).complement,
        (formik.values as any).neighborhood,
        (formik.values as any).city,
        (formik.values as any).federal_unity,
        debouncedGeocodeAddress
    ])

    const handleUfChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const uf = e.target.value;
        setSelectedUf(uf);
        if (onLocationSelected) {
            onLocationSelected(uf, '');
        }
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const city = e.target.value;
        if (onLocationSelected) {
            onLocationSelected(selectedUf, city);
        }
    };

    return (
      <>
        <TitleInput>{t("zip_code")}</TitleInput>
        <Field
            type="text"
            name="zip_code"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                    placeholder={t("type-zip-code")}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (field) {
                            field.onChange(e);
                            const zipCode = e.target.value.replace(/\D/g, '');
                            if (zipCode.length === 8) {
                                try {
                                    const addressData = await getAddressDataByZipCode(zipCode);
                                    if (addressData) {
                                        // Find UF in ufs array
                                        const ufMatch = ufs.find(uf => uf.uf === addressData.estado);
                                        if (ufMatch) {
                                            setSelectedUf(ufMatch.uf);
                                            // Use Formik context to update fields
                                            formik.setFieldValue('street', addressData.logradouro);
                                            formik.setFieldValue('complement', addressData.complemento);
                                            formik.setFieldValue('neighborhood', addressData.bairro);
                                            formik.setFieldValue('federal_unity', addressData.estado);
                                            formik.setFieldValue('city', addressData.cidade);
                                        }
                                    }
                                } catch (error) {
                                    console.error('Error fetching address:', error);
                                }
                            }
                        }
                    }}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="zip_code" />
        </ErrorText>

        <MarginBottom />

        <TitleInput>{t("street")}</TitleInput>
        <Field
            type="text"
            name="street"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                    placeholder={t("type-street")}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="street" />
        </ErrorText>

        <MarginBottom />

        <TitleInput>{t("house_number")}</TitleInput>
        <Field
            type="text"
            name="house_number"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                    placeholder={t("type-house-number")}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="house_number" />
        </ErrorText>

        <MarginBottom />

        <TitleInput>{t("complement")}</TitleInput>
        <Field
            type="text"
            name="complement"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                    placeholder={t("type-complement")}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="complement" />
        </ErrorText>

        <MarginBottom />

        <TitleInput>{t("neighborhood")}</TitleInput>
        <Field
            type="text"
            name="neighborhood"
            render={({
                field,
            }: {
                field?: FieldInputProps<any>;
            }) => (
                <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                    placeholder={t("type-neighborhood")}
                />
            )}
        />
        <ErrorText>
            <ErrorMessage name="neighborhood" />
        </ErrorText>

        <MarginBottom />
          <TitleInput>{t("federal_unity")}</TitleInput>
          <Field name="federal_unity">
              {({ field }: { field: FieldInputProps<any> }) => (
                  <select 
                      {...field}
                      onChange={(e) => {
                          field.onChange(e);
                          handleUfChange(e);
                      }}
                      style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc'
                      }}
                  >
                      <option value="">{t("select-state")}</option>
                      {ufs.map((uf) => (
                          <option key={uf.id} value={uf.uf}>
                              {uf.name}
                          </option>
                      ))}
                  </select>
              )}
          </Field>
          <ErrorText>
              <ErrorMessage name="federal_unity" />
          </ErrorText>

          <MarginBottom />

          <TitleInput>{t("city")}</TitleInput>
          <Field name="city">
              {({ field }: { field: FieldInputProps<any> }) => (
                  <select 
                      {...field}
                      onChange={(e) => {
                          field.onChange(e);
                          handleCityChange(e);
                      }}
                      style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc'
                      }}
                      disabled={!selectedUf}
                  >
                      <option value="">{t("select-city")}</option>
                      {cities.map((city) => (
                          <option key={city.id} value={city.name}>
                              {city.name}
                          </option>
                      ))}
                  </select>
              )}
          </Field>
          <ErrorText>
              <ErrorMessage name="city" />
          </ErrorText>

          <Field type="hidden" name="full_address" />
          <Field type="hidden" name="lat" />
          <Field type="hidden" name="lng" />
      </>
  );
};
