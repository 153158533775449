import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";

import {
    Button,
    Center,
    Container,
    FileListContainer,
    Input,
    InputContainer,
    InputError,
    InputLabel,
    Main,
    Select,
    Title,
    Textarea,
} from "./style";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useServiceProperty } from "../../hooks/serviceProperty";
import { useNavigate } from "react-router-dom";
import FileUploadSquare from "../../components/upload/fileUploadSquare";
import { useServiceHome } from "../../hooks/serviceHome";
import { PropertyLocationSelector } from "../../components/location-selector/property-location-selector";

export default function RegisterProperty() {
    const { createProperty } = useServiceProperty();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [files, setFiles] = useState({
        file_deed_number: "",
        file_cib: "",
        file_proof_of_residence: "",
        file_images: "",
    });
    const onFileSelected = (name: string) => (file: string) => {
        setFiles({ ...files, [name]: file });
    };
    const [buttonSave, setButtonSave] = useState(t("save"));
    const { getVegetations } = useServiceHome();
    const [vegetations, setVegetations] = useState<any[]>([]);
    const [clearFilesUpload, setClearFilesUpload] = useState(false);
    const [catalog, setCatalog] = useState("sell");

    useEffect(() => {
        async function getVegetationsAll() {
            await getVegetations()
                .then((response: any) => {
                    setVegetations(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        getVegetationsAll();
    }, []);

    const initialValues = {
        catalogs: "sell",
        name: "",
        deed_number: "",
        acre: "",
        cib_nirf_number: "",
        cost: "",
        description: "",
        vegetation: "dense",
        address: "",
        lat: "",
        lng: "",
        carbon_credits: "",
    };

    const formValidation = (values: typeof initialValues) => {};

    const handleSubmit = async (
        values: typeof initialValues,
        actions: FormikHelpers<typeof initialValues>
    ) => {
        setButtonSave(`${t("waiting")}...`);
        values.catalogs = catalog;
        values.cost = "0";
        values.carbon_credits = "0";

        await createProperty({
            ...values,
            ...files,
        })
            .then((response: any) => {
                if (catalog === "sell") {
                    toast.warning(t("property-analysis"), {
                        position: "top-center",
                    });
                } else {
                    toast.success(t("successfully"), {
                        position: "top-center",
                    });
                }
                setTimeout(() => {
                    navigate("/mySpaceSeller/environmental-portfolio");
                }, 4000);

                setClearFilesUpload(true);
            })
            .catch((error: any) => {
                if (error.response && error.response.data) {
                    const errors = error.response.data.errors;
                    const errors2 = error.response.data.message;

                    if (errors2 && !errors) {
                        toast.error(t(errors2), {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }

                    Object.entries(errors).forEach(
                        ([field, messages]: [field: any, messages: any]) => {
                            messages.forEach((message: string) => {
                                toast.error(t(message), {
                                    position: "top-right",
                                });
                            });
                        }
                    );
                }
            })
            .finally(() => {
                setButtonSave(`${t("save")}`);
            });
    };

    return (
        <Container>
            <Navbar />
            <ToastContainer />
            <Main>
                <Title>{t("register-property")}</Title>
                <Formik
                    validate={formValidation}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <InputContainer>
                            <div
                                style={{
                                    width: "100%",
                                    borderBottom: "1px solid #eee",
                                    paddingBottom: "30px",
                                    marginBottom: "30px",
                                }}
                            >
                                <InputLabel>{t("how_register")}</InputLabel>
                                <Field
                                    as={Select}
                                    type="text"
                                    name="catalog"
                                    required
                                    onChange={(event: any) => {
                                        const value = event.target.value;
                                        setCatalog(value);
                                    }}
                                >
                                    <option key={"sell"} value={"sell"}>
                                        {t("i-seller")}
                                    </option>
                                    <option key={"catalog"} value={"catalog"}>
                                        {t("i-catalog")}
                                    </option>
                                </Field>
                            </div>
                        </InputContainer>
                        <InputContainer>
                            <div>
                                <InputLabel>{t("name")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="name"
                                    minLength={6}
                                    required
                                />
                                <InputError>
                                    <ErrorMessage name="name" />
                                </InputError>

                                <InputLabel>{t("hectares")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="acre"
                                    required
                                />
                                <InputError>
                                    <ErrorMessage name="acre" />
                                </InputError>

                                <InputLabel>{t("number-doc")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="cib_nirf_number"
                                    required={catalog === "sell"}
                                    disabled={catalog === "catalog"}
                                />
                                <InputError>
                                    <ErrorMessage name="cib_nirf_number" />
                                </InputError>

                                <InputLabel>{t("vegetation")}</InputLabel>
                                <Field
                                    as={Select}
                                    type="text"
                                    name="vegetation"
                                    required
                                >
                                    {vegetations.map((v) => (
                                        <option key={v.slug} value={v.slug}>
                                            {t(`vegetation-${v.slug}`)}
                                        </option>
                                    ))}
                                </Field>
                                <InputError>
                                    <ErrorMessage name="vegetation" />
                                </InputError>
                                <InputLabel>{t("deed-number")}</InputLabel>
                                <Field
                                    as={Input}
                                    type="text"
                                    name="deed_number"
                                    required={catalog === "sell"}
                                    disabled={catalog === "catalog"}
                                />
                                <InputError>
                                    <ErrorMessage name="deed_number" />
                                </InputError>
                            </div>

                            <div>
                                <PropertyLocationSelector />
                            </div>
                        </InputContainer>
                        <InputContainer>
                            <div style={{ height: "auto" }}>
                                <InputLabel>{t("description")}</InputLabel>
                                <Field as={Textarea} name="description" />
                                <InputError>
                                    <ErrorMessage name="description" />
                                </InputError>
                            </div>
                        </InputContainer>

                        <InputContainer>
                            <div>
                                <InputLabel>{t("file")}</InputLabel>
                                <FileListContainer>
                                    {catalog === "sell" && (
                                        <>
                                            <FileUploadSquare
                                                title="CCIR"
                                                onFileSelected={onFileSelected(
                                                    "file_cib"
                                                )}
                                                clearFiles={clearFilesUpload}
                                            />
                                            <FileUploadSquare
                                                title={t("proof-residence")}
                                                onFileSelected={onFileSelected(
                                                    "file_proof_of_residence"
                                                )}
                                                clearFiles={clearFilesUpload}
                                            />
                                            <FileUploadSquare
                                                title={t("deed-file")}
                                                onFileSelected={onFileSelected(
                                                    "file_deed_number"
                                                )}
                                                clearFiles={clearFilesUpload}
                                            />
                                        </>
                                    )}
                                    <FileUploadSquare
                                        title={t("image-property")}
                                        multiple={true}
                                        onFileSelected={onFileSelected(
                                            "file_images"
                                        )}
                                        clearFiles={clearFilesUpload}
                                    />
                                </FileListContainer>
                            </div>
                        </InputContainer>

                        <Center>
                            <Button type="submit">{buttonSave}</Button>
                        </Center>
                    </Form>
                </Formik>
            </Main>

            <Footer />
        </Container>
    );
}
