import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useServiceHome } from '@/hooks/serviceHome';
import { UfData, CityData } from '@/models/LocationData';
import colors from '@/utils/colors';
import { useTranslation } from 'react-i18next';
import { useServiceUser } from '@/hooks/serviceUser';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-width: 200px;
`;

const LoadingIndicator = styled.div`
  position: absolute;
  right: 30px;
  top: 10px;
  bottom: 10px;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top: 2px solid ${colors.secondary};
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const CityList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const CityTag = styled.div`
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 16px;
`;

interface LocationRegisterProps {
  onLocationSelected?: (locations: CityData[]) => void;
  profileMode?: boolean;
}

export function LocationRegister(props: LocationRegisterProps) {
  const { t } = useTranslation();
  const { getUfs, getCities } = useServiceHome();
  const [ufs, setUfs] = useState<UfData[]>([]);
  const [cities, setCities] = useState<CityData[]>([]);
  const [selectedUf, setSelectedUf] = useState<string>('');
  const [selectedCities, setSelectedCities] = useState<CityData[]>([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);

  const {
    listEngineerCoverage,
    addEngineerCoverage,
    removeEngineerCoverage
  } = useServiceUser(); 

  useEffect(() => {
    if (props.profileMode) {
      listEngineerCoverage().then((response) => {
        let cities: any[] = response.map((item) => ({
          id: item.id,
          name: item.city,
          state_uf: item.uf,
          state_name: item.state_uf
        }));
        setSelectedCities(cities);
      })
    }
    loadUfs();
  }, []);

  useEffect(() => {
    if (selectedUf) {
      loadCities(selectedUf);
    }
  }, [selectedUf]);

  const loadUfs = async () => {
    try {
      const response = await getUfs();
      setUfs(response);
    } catch (error) {
      console.error('Error loading UFs:', error);
    }
  };

  const loadCities = async (uf: string) => {
    setIsLoadingCities(true);
    try {
      const response = await getCities(uf);
      setCities(response);
    } catch (error) {
      console.error('Error loading cities:', error);
    } finally {
      setIsLoadingCities(false);
    }
  };

  const handleUfChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUf(event.target.value);
  };

  const handleCitySelect = (city: CityData) => {
    if (!selectedCities.some(c => c.id === city.id)) {
      city.state_name = ufs.find(uf => uf.uf === city.state_uf)?.name;
      setSelectedCities([...selectedCities, city]);
      props.onLocationSelected?.([...selectedCities, city]);
      if (props.profileMode) {
        addEngineerCoverage({
          state_uf: city.state_name || "",
          city: city.name,
          uf: city.state_uf,
        });
      }
    }
  };

  const handleRemoveCity = (cityId: number) => {
    setSelectedCities(selectedCities.filter(city => city.id !== cityId));
    if (props.profileMode) {
      removeEngineerCoverage(cityId);
    }
  };

  return (
    <Container>
      <SelectContainer>
        <Select value={selectedUf} onChange={handleUfChange}>
          <option value="">{t('select-state')}</option>
          {ufs.map((uf) => (
            <option key={uf.id} value={uf.uf}>
              {uf.name}
            </option>
          ))}
        </Select>

        <SelectWrapper>
          <Select
            disabled={!selectedUf || isLoadingCities}
            onChange={(e) => {
              const city = cities.find(c => c.id === Number(e.target.value));
              if (city) handleCitySelect(city);
            }}
            value=""
          >
            <option value="">{t('select-city')}</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </Select>
          {isLoadingCities && <LoadingIndicator />}
        </SelectWrapper>
      </SelectContainer>

      <CityList>
        {selectedCities.map((city) => (
          <CityTag key={city.id}>
            {city.name} - {city.state_uf}
            <RemoveButton onClick={() => handleRemoveCity(city.id)}>×</RemoveButton>
          </CityTag>
        ))}
      </CityList>
    </Container>
  );
}
